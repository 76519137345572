import React from 'react';

const SVG = ({ color = 'var(--color-dark)', ...rest }) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 84 131.615"
    {...rest}
  >
    <title>Meetguns.com | Ganapati V S | logo</title>
    <g data-name="Layer 1">
      <g data-name="G logo">
        <g style={{ isolation: 'isolate' }}>
          <g style={{ isolation: 'isolate' }}>
            <path
              d="M99.94629,118.40074l-28.79883.14941c-14.84912,0-28.34863-2.39941-40.498-13.64941C20.89941,95.75133,17.74951,83.15172,17,66.3524c1.6499-30.59961,21.14893-52.498,55.498-52.94824,12.59961,1.0498,20.54883,3.75,25.79883,5.09961L81.19727,64.8524l19.499-.15039-0.75,46.94873v6.75Zm-47.248-14.84961L80.89648,25.55357C54.04834,22.40416,30.94922,35.90318,30.499,66.3524,30.04932,83.75133,39.64893,97.55113,52.69824,103.55113Zm12.14941,3.2998c7.65039,0,18.89941-.4502,22.94922-0.4502l0.4502-32.24951H75.79688Z"
              transform="translate(-16.7 -13.39998)"
              fill={color}
            />
          </g>
        </g>
        <path
          d="M99.95,111.65L100.7,64.7l-19.5.15L98.3,18.5c-5.25-1.35-13.2-4.05-25.8-5.1C38.15,13.85,18.65,35.75,17,66.35c0.75,16.8,3.9,29.4,13.65,38.55,12.15,11.25,25.65,13.65,40.5,13.65l28.8-.15v-6.75ZM30.5,66.35C30.95,35.9,54.05,22.4,80.9,25.55l-28.2,78C39.65,97.55,30.05,83.75,30.5,66.35Zm45.3,7.8H88.25L87.8,106.4c-4.05,0-15.3.45-22.95,0.45Z"
          transform="translate(-16.7 -13.39998)"
          fill={color}
        />
        <path
          d="M16.7,133.015h84v12h-84v-12Z"
          transform="translate(-16.7 -13.39998)"
          fill={color}
        />
      </g>
    </g>
  </svg>
);

export default SVG;
