module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-remark-images@7.13.1_gatsby-plugin-sharp@5.13.1_gatsby@5.13.7/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1500,"showCaptions":true,"quality":85,"withWebp":{"quality":85},"linkImagesToOriginal":false,"markdownCaptions":false,"backgroundColor":"white","withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-remark-autolink-headers@6.13.1_gatsby@5.13.7_react-dom@18.3.1_react@18.3.1/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":100,"className":"anchor"},
    },{
      plugin: require('../plugins/@jpfulton/gatsby-remark-copy-button/gatsby-browser.js'),
      options: {"plugins":[],"copySvg":"<svg class=\"copy-icon\" xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\">\n                  <g fill=\"none\" stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"2\">\n                      <path d=\"M8 10a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2h-8a2 2 0 0 1-2-2z\"/>\n                      <path d=\"M16 8V6a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2\"/>\n                  </g>\n              </svg>","successSvg":"<svg class=\"success-icon\" xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 16 16\">\n                  <path fill=\"none\" stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\" d=\"m2.75 8.75l3.5 3.5l7-7.5\"/>\n              </svg>"},
    },{
      plugin: require('../plugins/gatsby-remark-images-zoom/gatsby-browser.js'),
      options: {"plugins":[],"background":"rgba(0, 0, 0, 0.85)","zIndex":0},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.7_graphql@16.9.0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"short_name":"meetguns","name":"meetguns","icon":"src/images/icon-512x512.png","start_url":"/","scope":"/","display":"standalone","theme_color":"#0f0f10","background_color":"#0f0f10","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1e026321b286717a50609f8ba04214a8"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-analytics@5.13.1_gatsby@5.13.7_react-dom@18.3.1_react@18.3.1/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-52929584-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-styled-components@6.13.1_babel-plugin-styled-components@2.1.4_gatsby@5.13.7_rea_zpf5sokthloqfuumyumkmjgehe/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-typography@5.13.1_gatsby@5.13.7_react-dom@18.3.1_react-typography@0.16.23_react@18.3.1_typography@0.16.24/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-nprogress@5.13.1_gatsby@5.13.7/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"var(--color-accent)","showSpinner":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-page-progress@2.2.1/node_modules/gatsby-plugin-page-progress/gatsby-browser.js'),
      options: {"plugins":[],"includePaths":[{"regex":"^/blog/.*"}],"excludePaths":["/","/blog","/blog/"],"height":2,"prependToBody":false,"color":"var(--color-accent)","footerHeight":40,"headerHeight":0},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-twitter@5.13.1_gatsby@5.13.7/node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-catch-links@5.13.1_gatsby@5.13.7/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-offline@6.13.2_gatsby@5.13.7_react-dom@18.3.1_react@18.3.1/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.7_babel-eslint@10.1.0_react-dom@18.3.1_react@18.3.1_typescript@5.5.4/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
